html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Main content should take remaining space */
main {
  flex: 1;
}

/* Footer styles */
.footer {
  margin: 0;
  padding-bottom: 5px;
  height: 125px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2f2f2f;
  position: relative;
}

.links {
  display: flex;
  gap: 15px;
}

.link {
  text-decoration: none;
  color: #fff;
}

.status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.status-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.connected-region {
  font-size: 0.8em; /* Adjust the font size as needed */
}

.connected-region svg {
  margin-right: 1px;
  font-size: 1.2em; /* Adjust the icon size as needed */
}

li {
  padding: 10px;
  list-style: none;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s infinite;
}

.icon-spacing {
  margin-left: 10px;
}


.no-copy {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}


/* Existing styles here... */

.gpu-info {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  display: inline-block;
}

/* In your footer.css file */
.fan-icon {
  display: inline-block;
  transition: animation-duration 0.5s ease; /* Smooth transition of animation duration */
  font-size: 24px;
  transform-origin: center;
  animation: spin infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.gpu-details {
  font-size: 0.8em;
  text-align: center;
}

.tooltip {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -125px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gpu-info:hover .tooltip {
  visibility: visible;
  opacity: 1;
}