@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.height {
  padding: 2rem 0;
}

center {
  height: 500px;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
  animation: logo-spin 6s infinite linear;
  /* Uncomment the next line to use the invert filter */
  /* filter: invert(100%); */
}
