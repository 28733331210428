body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #232323; /* Optional: set a background color for better visibility */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
  min-width: 435px;
}

#root {
  width: 100%;
  height: 100%;
}
.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
