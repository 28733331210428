/* Container Styling */
.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(to bottom, #242121, #3a3a3a);
}

/* Content Styling */
.content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
}

/* Left Side (Animation) */
.left-side {
    flex: 1;
    position: relative;
}

/* Right Side (Text Content) */
.right-side {
    flex: 1;
    color: #f8f9fa;
}

.right-side h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.right-side p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Button Styling */
.explore-button {
    background-color: #28a745;
    color: #fff;
    padding: 12px 24px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.explore-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}