.main {
    width: 500px;
    height: 500px;
    background: rgb(29, 28, 28);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
  }
  
  #chk {
    display: none;
  }
  
  .signup,
  .login {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  label {
    color: #fff;
    font-size: 2.3em;
    justify-content: center;
    display: flex;
    margin: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
  
  input {
    width: 60%;
    height: 15px;
    background: #000000;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  
  button {
    width: 60%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    background: #2aa05b;
    font-size: 1em;
    font-weight: bold;
    margin-top: 30px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.2s ease-in;
    cursor: pointer;
  }
  
  button:hover {
    background: #29bb16;
  }
  
  .login {
    height: 460px;
    background: #2b2929;
    border-radius: 60% / 10%;
    transform: translateY(-180px);
    transition: 0.8s ease-in-out;
  }
  
  .login labels {
    color: #21b439;
    transform: scale(0.6);
  }
  
  #chk:checked ~ .login {
    transform: translateY(-500px);
  }
  
  #chk:checked ~ .login label {
    transform: scale(1);
  }
  
  #chk:checked ~ .signup label {
    transform: scale(0.6);
  }
  